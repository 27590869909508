<template>
  <!-- 
    画面: プランカテゴリ新規登録
    用途: プランカテゴリを新規登録する
   -->
  <div>

    <!-- #region TODO -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("planCategoryCreatePage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("planCategoryCreatePage.name") }}</strong></CCol>
                <CCol>
                  <CInput v-model="request.name" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.name.$dirty &&
                      !$v.request.name.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("planCategoryCreatePage.name") })}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.name.$dirty &&
                      !$v.request.name.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("roomCategoryEditPage.name"),vMax: $v.request.name.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("planCategoryCreatePage.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="request.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.request.description.$dirty &&
                      !$v.request.description.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("roomCategoryEditPage.description"),vMax: $v.request.description.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->

            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion TODO -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">

        <!-- #region 名称 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("planCategoryCreatePage.name") }}</strong></CCol>
          <CCol><span> {{ request.name }} </span></CCol>
        </CRow>
        <!-- #endregion 名称 -->

        <!-- #region 説明 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("planCategoryCreatePage.description") }}</strong></CCol>
          <CCol><span> {{ request.description }} </span></CCol>
        </CRow>
        <!-- #endregion 説明 -->

      </CForm>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton
          @click="checkModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          color="success"
          @click="onRegisterClicked()"
        >
          <i class="icon cil-save mr-1"></i> {{
          $t("common.register")
        }}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Check -->

    <!-- #region Success -->
    <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />
    <!-- #endregion Success -->

    <!-- #region Error -->
    <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />
    <!-- #endregion Error -->

  </div>
</template>

<script>
// import i18n from "../i18n";
// import axios from "axios";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {

  name: "PlanCategoryCreate",

  components: {
    SuccessModal,
    ErrorModal
  },

  computed: {
    activeStatus() {
      return this.$v.request.$invalid ? 'inactive' : 'active'
    },
    showSuccessModal() {
      return this.successModal;
    },
    uid() {
      return this.$store.state.uniqueId;
    },
  },
  
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    }
  },

  data() {
    return {

      //#region Flag

      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,

      //#endregion Flag

      //#region Message
      errorMessage:"error",
      //#endregion Message


      //#region request

      request: {
        name: "",
        description:"",
      },

      //#endregion request


      //#region Response data

      response: [],

      //#endregion Response data

    }
  },

  validations() {
    return {
      request: {
        name: {
          required,
          maxLength:maxLength(50)
         },
        description: {
          maxLength:maxLength(1000)
        },
      }
    };
  },

  methods: {

    //#region Event

    /** 確認ボタン押下 */
    onCheckClicked() {
      this.$v.request.$invalid ?
        this.$v.request.$touch() :
        this.checkModal = true;
    },

    /** 登録ボタン押下 */
    onRegisterClicked() {
      this.reqPost();
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    //#endregion Event


    //#region Request

    /** プランタイプ登録リクエスト */
    reqPost() {

      //TODO:開発用
      this.checkModal = false
      this.successModal = true;

      // const url = `/rest/hotel/${this.uid}/planCategory`;
      // const body = this.request;

      // axios
      //   .post(url, body, {
      //     headers: { "Accept-Language": this.$i18n.locale },
      //   })
      //   .then((resp) => {
      //     // TODO: API完成後に対応
      //     console.log("resp.data" + JSON.stringify(resp.data));
      //     this.checkModal = false
      //     this.successModal = true;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.errorMessage = err;
      //     this.errorModal = true;
      //   });
    },

    //#endregion Request


    //#region Method

    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method

  }
}
</script>
